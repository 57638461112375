<template>
    <v-container>
        <div v-if="subscriptionsEnabled">
            <v-card width="900" class="mx-auto">
                <v-card-title class="font-weight-light px-0 pt-0"><v-app-bar color="rfaccent2" dense dark>Membership Plans</v-app-bar></v-card-title>
                <v-card-text class="title font-weight-light">
                    <p>A subscription is required to use advanced features of myGMRS. We have the following options available to meet your needs:</p>

                    <!-- Mobile View -->
                    <div class="d-md-none">
                        <v-btn-toggle
                            v-model="selectedTier"
                            mandatory
                            class="d-flex justify-center my-4"
                        >
                            <v-btn value="premium" class="px-6">
                                <v-icon left>mdi-star</v-icon>
                                Premium
                            </v-btn>
                            <v-btn value="adFree" class="px-6">
                                <v-icon left>mdi-advertisements-off</v-icon>
                                Ad-Free
                            </v-btn>
                        </v-btn-toggle>

                        <v-card outlined :color="selectedTier === 'premium' ? 'rfaccent2' : undefined">
                            <v-card-title :class="['justify-center', selectedTier === 'premium' ? 'white--text' : '']">
                                <v-icon :color="selectedTier === 'premium' ? 'white' : undefined" left>
                                    {{ selectedTier === 'premium' ? 'mdi-star' : 'mdi-advertisements-off' }}
                                </v-icon>
                                {{ selectedTier === 'premium' ? 'Premium' : 'Ad-Free' }}
                                <v-chip v-if="selectedTier === 'premium'" class="ml-2" color="warning" small>Most Popular</v-chip>
                            </v-card-title>
                            <v-card-text :class="selectedTier === 'premium' ? 'white--text' : ''">
                                <div class="text-center mb-4">
                                    <div class="title font-weight-light">
                                        <span class="font-weight-black">${{ stripe.prices[selectedTier].monthly.amount }}
                                            <span class="font-weight-regular">/ month</span>
                                        </span>
                                        <br>
                                        <small>or</small>
                                        <br>
                                        <span class="font-weight-black">${{ stripe.prices[selectedTier].yearly.amount }}
                                            <span class="font-weight-regular">/ year</span>
                                        </span>
                                        <v-chip color="success" class="ml-2" x-small>
                                            SAVE {{ selectedTier === 'premium' ? yearlySavingsPercentagePremium : yearlySavingsPercentageAdFree }}%!
                                        </v-chip>
                                    </div>
                                </div>

                                <v-list :color="selectedTier === 'premium' ? 'rfaccent2' : undefined">
                                    <v-list-item v-for="(feature, i) in features[selectedTier]" :key="i">
                                        <v-list-item-icon>
                                            <v-icon color="success">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title :class="selectedTier === 'premium' ? 'white--text' : ''">
                                                {{ feature.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle :class="selectedTier === 'premium' ? 'white--text' : 'text--secondary'">
                                                {{ feature.text }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                                <div class="text-center mt-4">
                                    <v-btn color="rfaccent" dark block rounded class="mb-2" @click="submitMonthly">
                                        Subscribe Monthly
                                    </v-btn>
                                    <v-btn color="success" dark block rounded @click="submitYearly">
                                        Subscribe Yearly
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>

                    <!-- Desktop View -->
                    <div class="d-none d-md-block">
                        <v-row>
                            <v-col cols="6">
                                <v-card outlined class="h-100">
                                    <v-card-title class="justify-center">
                                        <v-icon left>mdi-advertisements-off</v-icon>
                                        Ad-Free
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-center mb-4">
                                            <div class="title font-weight-light">
                                                <span class="font-weight-black">${{ stripe.prices.adFree.monthly.amount }}
                                                    <span class="font-weight-regular">/ month</span>
                                                </span>
                                                <br>
                                                <small>or</small>
                                                <br>
                                                <span class="font-weight-black">${{ stripe.prices.adFree.yearly.amount }}
                                                    <span class="font-weight-regular">/ year</span>
                                                </span>
                                                <v-chip color="success" class="ml-2" x-small>SAVE {{ yearlySavingsPercentageAdFree }}%!</v-chip>
                                            </div>
                                        </div>

                                        <v-list>
                                            <v-list-item v-for="(feature, i) in features.adFree" :key="i">
                                                <v-list-item-icon>
                                                    <v-icon color="success">mdi-check-circle</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ feature.title }}</v-list-item-title>
                                                    <v-list-item-subtitle class="text--secondary">{{ feature.text }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>

                                        <div class="text-center mt-4">
                                            <v-btn color="rfaccent" dark block rounded class="mb-2" @click="selectedTier = 'adFree'; submitMonthly()">
                                                Subscribe Monthly
                                            </v-btn>
                                            <v-btn color="success" dark block rounded @click="selectedTier = 'adFree'; submitYearly()">
                                                Subscribe Yearly
                                            </v-btn>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="6">
                                <v-card outlined class="h-100" color="rfaccent2">
                                    <v-card-title class="justify-center white--text">
                                        <v-icon left color="white">mdi-star</v-icon>
                                        Premium
                                        <v-chip class="ml-2" color="warning" small>Most Popular</v-chip>
                                    </v-card-title>
                                    <v-card-text class="white--text">
                                        <div class="text-center mb-4">
                                            <div class="title font-weight-light">
                                                <span class="font-weight-black">${{ stripe.prices.premium.monthly.amount }}
                                                    <span class="font-weight-regular">/ month</span>
                                                </span>
                                                <br>
                                                <small>or</small>
                                                <br>
                                                <span class="font-weight-black">${{ stripe.prices.premium.yearly.amount }}
                                                    <span class="font-weight-regular">/ year</span>
                                                </span>
                                                <v-chip color="success" class="ml-2" x-small>SAVE {{ yearlySavingsPercentagePremium }}%!</v-chip>
                                            </div>
                                        </div>

                                        <v-list color="rfaccent2">
                                            <v-list-item v-for="(feature, i) in features.premium" :key="i">
                                                <v-list-item-icon>
                                                    <v-icon color="success">mdi-check-circle</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title class="white--text">{{ feature.title }}</v-list-item-title>
                                                    <v-list-item-subtitle class="white--text">{{ feature.text }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>

                                        <div class="text-center mt-4">
                                            <v-btn color="rfaccent" dark block rounded class="mb-2" @click="selectedTier = 'premium'; submitMonthly()">
                                                Subscribe Monthly
                                            </v-btn>
                                            <v-btn color="success" dark block rounded @click="selectedTier = 'premium'; submitYearly()">
                                                Subscribe Yearly
                                            </v-btn>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>

                    <StripeCheckout
                        ref="checkoutRef"
                        mode="subscription"
                        :pk="stripe.publishableKey"
                        :session-id="stripe.sessionId"
                        :client-reference-id="user.id.toString()"
                        :customer-email="user.email"
                        :success-url="stripe.successURL"
                        :cancel-url="stripe.cancelURL"
                        @loading="v => stripe.loading = v"
                        v-if="stripe.publishableKey"
                    />

                    <p class="caption ma-5 pb-0 font-weight-light text-center"><em>* A 10% processing fee applies to payments collected by myGMRS.
                        Renewals are charged automatically to your members. Payout of membership fees is by PayPal
                        account or paper check.</em></p>

                    <div class="text-center">
                        <v-btn text small @click="goBack">Go Back</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" md="6" lg="6" xl="4">
                    <v-card color="rforange" light>
                        <v-card-title class="display-1 justify-center"><v-icon size="100">mdi-traffic-cone</v-icon></v-card-title>
                        <v-card-title class="display-1 justify-center pt-0">Coming Soon</v-card-title>
                        <v-card-text class="subtitle-1 text-center">
                            This feature is still being worked on and will be coming soon. Please check back later!
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import {StripeCheckout} from '@vue-stripe/vue-stripe';
    import axios from "axios";
    import config from "@/config";

    export default {
        components: {StripeCheckout},

        props: ['user'],

        data: () => ({
            features: {
                premium: [
                    {title: 'No Ads', text: 'Get a 100% ad-free experience'},
                    {title: 'Generate PDF Reports', text: 'Reports of repeaters, access requests, and active licenses by state'},
                    {title: 'Generate CHIRP CSV Files', text: 'Export repeaters compatible with CHIRP radio software'},
                    {title: 'Classifieds Section', text: 'List items for sale or request items you want to buy'},
                    {title: 'Create a Radio Club Page', text: 'Get a forum, calendar, and photo gallery hosted by us'},
                    {title: 'Collect Membership Fees for your Club', text: 'Accept Credit Card or PayPal Payments* for paid clubs'},
                    {title: 'Premium Member Forum Badge', text: 'Wear it with pride!'},
                    {title: 'Priority Customer Support', text: 'Guaranteed 24-hour Response'},
                    {title: 'No Contracts or Commitments', text: 'Cancel Anytime'},
                ],
                adFree: [
                    {title: 'No Ads', text: 'Get a 100% ad-free experience'},
                    {title: 'Generate PDF Reports', text: 'Reports of repeaters and active licenses by state'},
                    {title: 'Ad-Free Supporter Forum Badge', text: 'Wear it with pride!'},
                    {title: 'No Contracts or Commitments', text: 'Cancel Anytime'},
                ]
            },
            selectedTier: 'premium', // or 'adFree'
            stripe: {
                loading: false,
                publishableKey: null,
                sessionId: null,
                successURL: `${config.UI_LOCATION}/profile/subscriptions/success`,
                cancelURL: `${config.UI_LOCATION}/profile/subscriptions`,
                prices: {
                    premium: {
                        monthly: {
                            id: null,
                            amount: 4.99
                        },
                        yearly: {
                            id: null,
                            amount: 49.99
                        }
                    },
                    adFree: {
                        monthly: {
                            id: null,
                            amount: 1.99
                        },
                        yearly: {
                            id: null,
                            amount: 19.99
                        }
                    }
                },
                
            },
            yearlySavingsPercentagePremium: 0,
            yearlySavingsPercentageAdFree: 0,
            subscriptionsEnabled: config.SUBSCRIPTIONS_ENABLED
        }),

        created() {
            this.loadStripeSetup();
        },

        methods: {
            goBack() {
                this.$router.go(-1);
            },

            submitYearly() {
                this.createCheckoutSession(this.stripe.prices[this.selectedTier].yearly.id);
            },

            submitMonthly() {
                this.createCheckoutSession(this.stripe.prices[this.selectedTier].monthly.id);
            },

            async loadStripeSetup() {
                try {
                    let axiosHeaders = {};
                    if (this.user && this.user.authToken) {
                        axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;
                    }

                    const response = await axios.get(`${config.API_LOCATION}/subscription/setup`, {
                        headers: axiosHeaders
                    });
                    
                    this.stripe.publishableKey = response.data.publishableKey;
                    this.stripe.prices.premium.monthly.id = response.data.prices.premium.monthly;
                    this.stripe.prices.premium.yearly.id = response.data.prices.premium.yearly;

                    this.stripe.prices.adFree.monthly.id = response.data.prices.adFree.monthly;
                    this.stripe.prices.adFree.yearly.id = response.data.prices.adFree.yearly;

                    // Calculate yearly savings percentages
                    const premiumMonthlyAnnual = this.stripe.prices.premium.monthly.amount * 12;
                    const adFreeMonthlyAnnual = this.stripe.prices.adFree.monthly.amount * 12;

                    this.yearlySavingsPercentagePremium = Math.round(((premiumMonthlyAnnual - this.stripe.prices.premium.yearly.amount) / premiumMonthlyAnnual) * 100);
                    this.yearlySavingsPercentageAdFree = Math.round(((adFreeMonthlyAnnual - this.stripe.prices.adFree.yearly.amount) / adFreeMonthlyAnnual) * 100);
                } catch (err) {
                    console.error('Failed to load Stripe setup:', err);
                }
            },

            createCheckoutSession(priceId) {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .post(`${config.API_LOCATION}/subscription/session`, {
                        priceId: priceId
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.stripe.sessionId = response.data.sessionId;

                        this.$refs.checkoutRef.redirectToCheckout();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        },
    }
</script>
